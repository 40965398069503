import "./styles.css";
import { createForm } from "final-form";
import Cleave from "cleave.js";
import axios from "axios";

(async () => {
  //   const apiUrl = "https://owhr86ig97.execute-api.us-east-1.amazonaws.com/staging/dispensaries/webform";
  // PROD URL
  const apiUrl =
    "https://n1cou5uc66.execute-api.us-east-1.amazonaws.com/prod/dispensaries/webform";
  // BTS API
  //   const apiUrl = "https://b1ryb762lg.execute-api.us-east-1.amazonaws.com/testStage/webform";
  // Stage API
  // const apiUrl =
  //   "https://owhr86ig97.execute-api.us-east-1.amazonaws.com/staging/dispensaries/webform";

  const formEl = document.querySelector("form");
  const messageEl = document.querySelector("#message");
  const dispensaryId = 1;
  let patientCase;

  const showMessage = (message) => {
    formEl.style.display = "none";
    messageEl.style.display = "block";
    messageEl.innerHTML = message;
  };

  const onSubmit = async (values) => {
    console.log("Values :: ", values);
    try {
      const body = JSON.stringify({
        ...values,
        phoneNumber: values.phoneNumber
          ? `+1${values.phoneNumber.replace(/\D/g, "")}`
          : "",
        dispensaryId,
      });

      console.log("Body :: ", config);

      var config = {
        method: "post",
        url: apiUrl,
        data: body,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          showMessage(
            "Thanks for the info!  A representative will be in touch shortly. <br/> <center>Your Case Ref no is : <b> " +
              response.data.body +
              " </b></center>"
          );
        })
        .catch(function (error) {
          console.log(error);
          showMessage(
            "Oops! Something Went Wrong. Try Again",
            JSON.stringify(error)
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  const form = createForm({
    onSubmit,
    initialValues: patientCase
      ? {
          emailAddress: patientCase.inboundEmailAddress,
          phoneNumber: patientCase.patient.phoneNumber,
          firstName: patientCase.patient.firstName,
          lastName: patientCase.patient.lastName,
          inquiry,
          dispensaryId,
          mmcc,
          age,
        }
      : {},
    validate: (values) => {
      const errors = {};
      if (!values.firstName) {
        errors.firstName = "First Name is required!";
      }
      if (!values.lastName) {
        errors.lastName = "Last Name is required!";
      }
      if (!values.category) {
        errors.phoneNumber = "Category is required!";
      }
      if (!values.emailAddress) {
        errors.emailAddress = "Email Address is required!";
      }
      if (!values.inquiry) {
        errors.inquiry = "Inquiry Message is required!";
      }
      return errors;
    },
  });

  formEl.addEventListener("submit", (event) => {
    event.preventDefault();
    form.submit();
  });

  const registered = {};

  const registerField = (input) => {
    const { name } = input;
    form.registerField(
      name,
      (fieldState) => {
        const { blur, change, error, focus, touched, value } = fieldState;
        const errorElement = document.getElementById(`${name}_error`);
        if (!registered[name]) {
          // first time, register event listeners
          input.addEventListener("blur", () => blur());
          input.addEventListener("input", (event) =>
            change(
              input.type === "checkbox"
                ? event.target.checked
                : event.target.value
            )
          );
          input.addEventListener("focus", () => focus());
          registered[name] = true;
        }

        input.value = value === undefined ? "" : value;

        // show/hide errors
        if (errorElement) {
          if (touched && error) {
            errorElement.innerHTML = error;
            errorElement.style.display = "block";
          } else {
            errorElement.innerHTML = "";
            errorElement.style.display = "none";
          }
        }
      },
      {
        value: true,
        error: true,
        touched: true,
      }
    );
  };

  [...document.forms[0]].forEach((input) => {
    if (input.name) {
      registerField(input);
    }
  });

  new Cleave(".phone-input", {
    numericOnly: true,
    blocks: [0, 3, 0, 3, 4],
    delimiters: ["(", ")", " ", "-"],
  });
})();
